import React from 'react'

import { Box, Stack, Typography, Grid, Button, Zoom } from '@mui/material'
import { t } from 'i18next'
import { Lock, QuestionMarkOutlined, AccountCircle } from '@mui/icons-material'

import { Image } from 'components/common'
import { TokenStorage } from 'services'
import { CourseType, SingletonType, UserType } from 'api/generated'

interface ContentListProps<T> {
    items: T[]
    onClick: (item: T) => void
    onGetAccess?: (item: T) => void
    onAuthorClick?: (id: string) => void
    authored?: boolean
}

const ContentList = <T extends CourseType | SingletonType>({
    items,
    onClick,
    onGetAccess,
    authored = false,
    onAuthorClick,
}: ContentListProps<T>) => {
    const renderLock = (item: T) => {
        // eslint-disable-next-line no-underscore-dangle
        const isSingleton = item.__typename === 'SingletonType'

        if (isSingleton) {
            if (
                (item as SingletonType).isPublic &&
                !TokenStorage.isAuthenticated()
            ) {
                return (
                    <Button
                        startIcon={<Lock />}
                        onClick={() => onGetAccess?.(item)}
                        sx={{
                            position: 'absolute',
                            left: '25px',
                            top: '25px',
                            zIndex: 3,
                            padding: '1px 12px 1px 5px',
                            borderRadius: '17.5px',
                            '& .MuiSvgIcon-root': {
                                fontSize: '32px',
                                background: '#564967',
                                padding: '5px',
                                borderRadius: '50%',
                                color: 'white',
                            },
                            '@media (max-width:430px)': {
                                left: '15px',
                            },
                        }}
                    >
                        {t('contentList.badges.loginToGetAccess')}
                    </Button>
                )
            }
        }

        return (
            <Lock
                sx={{
                    fontSize: '35px',
                    position: 'absolute',
                    left: '25px',
                    top: '25px',
                    zIndex: 2,
                    background: 'rgba(0, 0, 0, .8)',
                    padding: '5px',
                    borderRadius: '50%',
                    '@media (max-width:430px)': {
                        left: '15px',
                    },
                }}
            />
        )
    }

    const renderAuthor = (author: UserType) => {
        return (
            <Stack
                onClick={() => onAuthorClick?.(author.id)}
                direction="row"
                alignItems="center"
                gap="8px"
                sx={theme => ({
                    backgroundColor: theme.colors.primary,
                    position: 'absolute',
                    top: '7%',
                    left: '3%',
                    zIndex: 5,
                    padding: '6px 10px',
                    border: `1px solid ${theme.colors.secondaryDark}`,
                    boxShadow: '0 0 3px #000',
                    cursor: 'pointer',
                    '&:hover': {
                        '& .MuiTypography-root, & .MuiSvgIcon-root': {
                            color: 'white',
                        },
                    },
                })}
            >
                <AccountCircle
                    sx={theme => ({
                        fontSize: '26px',
                        color: theme.colors.secondaryDark,
                    })}
                />
                <Typography
                    fontSize="18px"
                    sx={theme => ({
                        color: theme.colors.secondaryDark,
                    })}
                >
                    {author?.name}
                </Typography>
            </Stack>
        )
    }

    const renderItems = () =>
        items.map((item: T) => {
            return (
                <Zoom in key={item.id}>
                    <Grid
                        item
                        sm={6}
                        xs={12}
                        xl={4}
                        sx={{
                            width: '100%',
                            position: 'relative',
                        }}
                    >
                        {!item.hasAccess && renderLock(item)}
                        {authored && renderAuthor(item.coach)}
                        <Box
                            sx={theme => ({
                                minWidth: '250px',
                                minHeight: '200px',
                                flexBasis: '100%',
                                position: 'relative',
                                width: '100%',
                                height: '350px',
                                cursor: 'pointer',
                                fontSize: 0,
                                backgroundColor: theme.colors.background,
                                backgroundSize: 'cover',
                                boxShadow: '0 0 7px #000',
                                border: '1px solid #222',
                                ...(!item.hasAccess && {
                                    filter: 'grayscale(1)',
                                    pointerEvents: 'none',
                                }),
                                '&:hover': {
                                    borderColor: theme.colors.primary,
                                },
                            })}
                            onClick={() => {
                                if (item.hasAccess) {
                                    onClick(item)
                                }
                            }}
                        >
                            {item.background?.img800x400 ? (
                                <Image
                                    src={item.background.img800x400 as string}
                                    alt="content-background"
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            ) : (
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    height="100%"
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                >
                                    <QuestionMarkOutlined
                                        sx={{
                                            color: '#4e4e4e',
                                            fontSize: '205px',
                                        }}
                                    />
                                </Stack>
                            )}

                            <Stack
                                sx={{
                                    alignItems: 'start',
                                    position: 'absolute',
                                    bottom: 0,
                                    background: 'rgba(0, 0, 0, .8)',
                                    zIndex: '2',
                                    width: '100%',
                                    padding: '15px',
                                    borderTop: '1px solid #222',
                                    boxShadow:
                                        '0 -2px 2px -1px rgb(203 172 247)',
                                }}
                                alignItems="center"
                                justifyContent="flex-end"
                                direction="row"
                                gap="10px"
                            >
                                <Typography fontSize="23px" variant="b1">
                                    {item.name}
                                </Typography>
                            </Stack>
                        </Box>
                    </Grid>
                </Zoom>
            )
        })

    return (
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2 }}>
            {renderItems()}
        </Grid>
    )
}
export default ContentList
